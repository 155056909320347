// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.css-1e2bxag-MuiDataGrid-root {
    .MuiDataGrid-columnHeader:focus {
        outline: none !important;
    }
}
.css-1e2bxag-MuiDataGrid-root {
    .MuiDataGrid-cell:focus {
        outline: none !important;
    }
}
body {
    position: relative;
}

#root{
    height: 100vh !important;
}