.content { 
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; 
    padding:1em;
    background-color: #092C4C;
    color: #fff;
    font-size: 14px;
    min-height: 100vh!important;
  } 


 
  