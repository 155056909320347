// Here you can add other styles
.css-iclwpf .MuiDataGrid-columnHeader:focus, .css-iclwpf .MuiDataGrid-cell:focus {
    outline: none !important;
}
.sidebar-nav::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: var(--nav-link-hover);
}
.sidebar-nav::-webkit-scrollbar {
	width: 6px;
	background-color: var(--nav-link-hover);
}
.sidebar-nav::-webkit-scrollbar-thumb {
	background-color: var(--secondary-clr);
}
.sidebar {
    background-color: #7ab775;
    color: var(--primary-clr);
}
.sidebar-nav .nav-link:hover {
    background-color: #5e935a !important;
}
.sidebar-nav .nav-group.show .nav-link:hover {
    background-color: rgb(94 147 90) !important;
    color: var(--white-color);
}
.sidebar-nav {
    .nav-group-toggle::after {
        color: var(--white-color) !important;
    }
}
.sidebar-nav .nav-link.active, .sidebar-toggler, a.nav-link.active {
    background-color: rgba(19, 90, 195, 0.10) !important;
    font-weight: 700;
}
a.nav-link {
    color: var(--white-color) !important;
    font-weight: var(--font-wt500);
}
.sidebar-brand {
    background-color: var(--primary-text);
    // border-bottom: 1px solid var(--dark-primary-color);
}
.sidebar-nav .nav-link:hover, .sidebar-nav .nav-group.show, .sidebar-nav .nav-group.show .nav-group-toggle {
    background-color: rgba(255, 255, 255, 0.11);
    color: var(--white-color);
}
// .nav-group-toggle::after {
//     filter: invert(1);
// }
.sidebar-fixed.sidebar-narrow-unfoldable:not(:hover), .sidebar-fixed.sidebar-narrow.hide, .sidebar.sidebar-fixed.hide {
    width: 3.8em;
}
.unblockBtn {
    background-color: rgb(94 147 90)!important;
    border-radius: 4px;
    line-height: 0;
    img {
        filter: brightness(15);
        width: 20px;
    }
}
.blockBtn {
    background-color: rgb(229, 83, 83)!important;
    border-radius: 4px;
    line-height: 0;
    img {
        filter: brightness(15);
        width: 20px;
    }
}
.action-btn {
    background-color: rgb(94 147 90)!important;
    border-radius: 4px;
    line-height: 0;
        img {
            filter: brightness(15);
            width: 20px;
        }
}
img.eyeIcon {
    filter: invert(1);
}
span.eye {
    top: 12px;
    right: 10px;
}
button.btn.btn-secondary, button.btn.btn-primary, button.btn.btn-outline-secondary.active {
    background-color: var(--secondary-clr);
    color: var(--white-color);
    border: none;
}
.icn-btn {
    background-color: transparent !important;
    color: var(--secondary-clr) !important;
}
// .icn-btn:hover {
//     color: var(--white-color) !important;
// }
.card.text-high-emphasis-inverse {
    background-color: var(--white-color) !important;
    color: var(--secondary-clr) !important;
    position: relative;
    box-shadow: 0px 5px 38px rgba(0, 0, 0, 0.07);
}
.card.text-high-emphasis-inverse .card-body {
    padding: 20px !important;
}
.card.text-high-emphasis-inverse .card-body {
    font-size: 12px;
}
.MuiDataGrid-columnHeaderTitle.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
}
.MuiAvatar-root.MuiAvatar-circular {
    margin-left: 0 !important;
}
.MuiChip-root.MuiChip-outlined, .MuiChip-root.MuiChip-filled {
    padding: 2px 5px;  
}
.MuiTablePagination-displayedRows {
    margin-bottom: 0;
}
.card.text-high-emphasis-inverse:before {
    content: '';
    width: 3px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 50%;
    background-color: var(--secondary-clr);
    z-index: 99;
    transform: translate(0, -50%);
}
button.header-toggler.ps-1:hover {
    background-color: var(--secondary-clr);
    color: var(--white-color) !important;
}
.card-header {
    background-color: var(--primary-text);
    color: var(--white-color) !important;
}
// button:hover, .action-btn:hover {
//     background-color: #55505a !important;
// }
.action-btn:active, button.btn:active {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}
button.header-toggler.ps-1 {
    padding: 0 5px !important;
    border-radius: 0 !important;
}
button.header-toggler.ps-1 {
    padding: 0 5px !important;
}
h4, .h4 {
    font-size: var(--font-20);
}
form label {
    margin-bottom: 8px !important;
}
form label, label {
    margin-bottom: 8px !important;
    font-weight: 600;
}
form input, select, textarea {
    min-height: 50px;
    background-color: #EDEDED !important;
    border: 1px solid #b1b7c1 !important;
}
// .MuiButtonBase-root:hover {
//     background-color: transparent !important;
// }
.content-main > div {
    margin-top: 0 !important;
}
.form-control:disabled {
    background-color: #f7f7f7 !important;
    border: 1px solid #c9c9c9 !important;
    color: var(--secondary-clr);
}
.loader {
    min-height: 780px;
}
form.login-form input.btn, form.forgot-form input.btn {
    background-color: rgb(94 147 90) !important;
}

.MuiTablePagination-actions button.Mui-disabled {
    background-color: var(--secondary-clr) !important;
    margin: 3px;
    opacity: 0.3 !important;
}
.MuiTablePagination-actions button {
    opacity: 1;
    background-color: var(--secondary-clr) !important;
    margin: 3px;
}
.MuiTablePagination-actions button svg {
    fill: #ffffff;
}
.top-calendar-bar {
    background-color: var(--secondary-clr);
}
.cal-days span:hover {
    cursor: pointer;
    background-color: #ffffff;
    color: var(--secondary-clr) !important;
    transition: 0.3s;
}
.arrow.arrow-next img {
    transform: rotate(180deg);
}
button.icn-btn {
    top: 0;
    right: 0;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 10px;
}
.calendar-div input, .calendar-div select {
    min-height: auto;
}
.calendar-div .col-md-4 > div {
    min-height: 275px;
}
.border-blue {
    border-color: #606CFF !important;
}
.count-blue {
    background-color: #606CFF !important;
    color: #ffffff;
}
.border-pink {
    border-color: #FE699D !important;
}
.count-pink {
    background-color: #FE699D !important;
    color: #ffffff;
}
.border-green {
    border-color: #75DDD6 !important;
}
.count-green {
    background-color: #75DDD6 !important;
    color: #ffffff;
}
.border-yellow {
    border-color: #FBE048 !important;
}
.count-yellow {
    background-color: #FBE048 !important;
    color: var(--secondary-clr);
}
.border-orange {
    border-color: #F47621 !important;
}
.count-orange {
    background-color: #F47621 !important;
    color: #ffffff;
}
.cloud-board-img span {
    bottom: 0;
    left: 0;
    padding: 1px 5px;
    font-size: 10px;
}
.challenges span {
    font-size: 12px;
}
.fw500{
    font-weight: 500;
}
label+.css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root select {
    padding: 0 10px;
    min-height: 40px;
}
label+.css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root svg {
    right: 5px;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline {
    min-height: 42px;
    margin-right: 10px;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary {
    min-height: 42px;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary select {
    padding: 0 5px;
    min-height: 40px;
    margin: 0;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary svg {
    right: 1px !important;
    background: #ededed;
    top: 1px;
    height: 40px;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary {
    border: none !important;
    box-shadow:  none !important;
}
.css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
    border-bottom: none !important;
}
input[type=file] {
    min-height: auto;
}
// .card.userChannel {
//     min-height: 426px;
// }

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root:hover, .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
    padding: 5px;
    background-color: transparent !important;
}
select.form-control {
    appearance: auto;
}
.MuiDataGrid-filterForm input.form-control:focus, select.form-control:focus, input.btn:focus {
    box-shadow: none;
}
.MuiFormControl-root.MuiDataGrid-filterFormOperatorInput {
    width: 140px;
}
.form-control:focus {
    box-shadow: none !important;
}
form.login-form button:hover {
    background-color: transparent !important;
}
.dropdown-item:active {
    color: var(--cui-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--secondary-clr);
}
.action-btn.bg-danger img {
    filter: brightness(15);
}
.simplebar-content ul.nav-group-items .nav-group-items {
    padding-left: 20px;
}

// 21-02-2024

.dasboardCards {
    .custom_cards {
        width: 24%;
        .cards {
            min-height: 200px;
        }
    }
}
.dashboardIcon {
    color: var(--primary-clr);
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: var(--primary-clr) !important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: var(--primary-clr) !important;
}
.dropdown-menu .dropdown-item:hover {
    background-color: var(--secondary-clr) !important;
    color: var(--white-color) !important;

}
.notificationDropdown {
    top: 65px;
    right: 15px;
    width: 400px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
table th a, table td a {
    color: #333333 !important;
    text-decoration: none;
}
.notificationContent {
    width: 70%;
}
.notificationDetail {
    width: 30%;
}
.notificationDetail span {
    font-size: 12px;
}
.notificationDropdown .tab-content {
    max-height: 500px;
    overflow-y: scroll;
}

.notificationDropdown .tab-content::-webkit-scrollbar-track
{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.notificationDropdown .tab-content::-webkit-scrollbar
{
	width: 0;
	background-color: #F5F5F5;
}

.notificationDropdown .tab-content::-webkit-scrollbar-thumb
{
	background-color: #000000;
}
.custom-primary {
    background-color: var(--secondary-clr);
}

.thankYou-bg {
    background: #BA8B02;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, rgba(44, 172, 226, .5), rgba(163, 210, 140, .5));  /* Chrome 10-25, Safari 5.1-6 */

    background: linear-gradient(to top, rgba(44, 172, 226, .5), rgba(163, 210, 140, .5)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.groupedList-container {
    height: 800px;
    overflow-y: scroll;
}
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 2px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #8d8d8d;
}
.cursor{
    cursor: pointer;
}
.groupedList-container .active, .groupedList-container > div:hover {
    background-color: #5e935a !important;
    color: #ffffff !important;
    transition: .2s;
}

@media only screen and (max-width: 1024px)  {
    .car .lev-unlock-div p {
        font-size: 12px;
    }
    .car .lev-unlock-div .card-inner {
        padding: 5px !important;
    }
}

@media only screen and (max-width: 767px)  {
    .table-card, .table-card .card-body {
        overflow-x: scroll;
    }
    .lev-unlock-div {
        width: 48%;
        margin-bottom: 20px;
    }
    .MuiDataGrid-filterForm {
        display: block !important;
    }
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiDataGrid-paper {
        min-width: 60% !important;
        width: 60%;
    }
    .MuiDataGrid-filterForm .MuiFormControl-root.MuiDataGrid-filterFormColumnInput {
        display: block;
        width: 90%;
    }
    .MuiDataGrid-filterForm .MuiFormControl-root.MuiDataGrid-filterFormColumnInput .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl {
        width: 100%;
    }
    .MuiFormControl-root.MuiDataGrid-filterFormOperatorInput {
        width: 95%;
    }
    .search-container {
        width: 100% !important;
    }

}
@media only screen and (max-width: 1199px)  {
    .card-header.cardNameHeader {
        font-size: 13px;
    }
    .table-card, .table-card .card-body {
        overflow-x: scroll;
    }
}
