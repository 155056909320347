.full { 
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; 
    padding:1em;
    background-color: #092C4C;
    color: #fff;
    font-size: 14px;
    } 

a{
    color: #fff;
    text-decoration: underline;
}