.loader.video-loader{
    min-height: unset;
    border-radius: var(--cui-modal-border-radius);
}
.loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.95);
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader > div {
    justify-content: center;
    align-items: center;
    height: 100%;
}
.loader img {
    width: 300px;
}


.loader img {
    width: 300px;
}