.page-link.active, .active > .page-link{
    background-color: var(--primary-clr);
    color:var(--white) !important;
    border:solid 1px var(--primary-clr);
} 

.LogoAI{
    background: #000000;
    width: 30%;
}

button.btn.btn-secondary, button.btn.btn-primary, button.btn.btn-outline-secondary.active, .btn.btn-primary.custom-primary {
    background-color: rgb(94 147 90);
    color: var(--white-color);
    border: none;
}

.btn:hover, .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--white-color) !important;
    background-color: rgba(73 119 69) !important;
    border-color: rgba(73 119 69) !important;
}

/* .sidebar-nav .nav-group.show .nav-group-toggle{
    background-color: var(--primary-clr);
} */

.sidebar-nav .nav-link:hover {
    background-color: rgb(94 147 90) !important;
}

/* /* .sidebar-nav .nav-group.show .nav-link:hover {
    background-color: var(--primary-clr);
} */

.sports-interest>div.MuiFormControl-root {
    margin: 0!important;
    width:100%;
}

.sports-interest .sport-ints {
    background-color: #ededed!important;
    border: 1px solid #b1b7c1!important;
    width: 100% !important;
}

.sports-interest .MuiOutlinedInput-notchedOutline{
    border: none !important;
}

.action-btn{
    cursor:pointer;
}

html:not([dir=rtl]) .sidebar-nav .nav-group-items .nav-link {
    padding-left: 20px;
}

.sidebar-nav .nav-group-items  > .nav-group > .nav-group-items{
  padding-left: 0 !important;  
}

.sidebar-nav .nav-group-items  > .nav-group > .nav-group-items .nav-link {
    padding-left: 40px;
}

.membership_profile_thumb{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-size: 100% !important;
    border: solid 1px #4F5D73 ;
}

.user_profile_thumb{
    width:60px;
    height: 60px;
    border-radius: 50%;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
}

.password-container .toggle-psd{
    right: 10px;
    top: 42px;
    cursor: pointer;
}

.inviteBtn{
    border:solid 1px var(--primary-clr);
    color:var(--primary-clr);
}


.sidebar-nav .nav-link.active, .sidebar-toggler, a.nav-link.active {
    background-color: rgb(94 147 90)!important; 
}

.custom-pagenation .ant-pagination-options{
    position: absolute !important;
    left:0 !important;
}

.search-close-btn{
    background: var(--secondary-clr);
    color:var(--white-color);
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 50%;
    height: 24px;
    width:24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    position: absolute;
    cursor:pointer;
}

.serach-container{
    display: flex;
    padding: var(--cui-card-spacer-y) var(--cui-card-spacer-x);
}

.serach-container > .form-control:focus{
    border: 1px solid var(--primary-clr) !important;
}

.content-main.drill{
    height: 70vh;
    overflow-y: scroll;
}

/* .video-lst-card{
    
} */

.custom_cards .card .card-body > div{
    width:100%;
}

.info_lable_width{
    min-width: 170px;
    word-break: break-all;
}

@media (max-width: 1199.98px) { 
    .overflow-x{
    overflow-x: scroll;
  } 
}